@import '~stylevariables/breakpoints.scss';

$assetModalBreak: 800px;

.AssetModal {
  padding: 0;
  @include breakpoint($assetModalBreak) {
    padding: 3em;
  }
}

.AssetModal-inner {
  width: 100%;
  @include breakpoint($assetModalBreak) {
    width: auto;
  }
}

.AssetDownload {
  background-color: #fff;
  display: grid;
  grid-gap: 1.5em;
  padding: 2em 1em;
  max-width: 1200px;
  min-height: 100vh;
  margin: auto;
  width: 100%;

  @include breakpoint(400px) {
    padding: 3em;
  }

  @include breakpoint($assetModalBreak) {
    align-items: center;
    grid-template-columns: 1fr auto;
    grid-template-rows: 100%;
    min-height: unset;
  }
}
.AssetDownload-close {
  background-color: transparent;
  border: 0;
  position: fixed;
  padding: 5px;
  z-index: 2;
  top: 0;
  right: 0;

  @include breakpoint(500px) {
    top: 12px;
    right: 12px;
  }
}
$assetDownloadPadding: 1.5rem;
.AssetDownload-preview {
  align-self: center;
  height: 100%;
  overflow-y: auto;
  display: flex;
  justify-content: center;
  align-items: center;
}
.AssetDownload-preview-imagewrap {
  display: inline-block;
  padding-right: $assetDownloadPadding;
  padding-bottom: $assetDownloadPadding;
  position: relative;
}
.AssetDownload-preview-image {
  display: block;
  max-width: 100%;
  max-height: 50vh;
  //width: 100%;
}
.AssetDownload-controls-sizes {
  margin-top: .25em;
  display: grid;
  grid-gap: 1em;
  grid-template-columns: auto 1fr;
}
.AssetDownload-preview-width,
.AssetDownload-preview-height {
  align-items: center;
  display: flex;
  position: absolute;
  justify-content: center;
  height: $assetDownloadPadding;
  width: calc(100% - #{$assetDownloadPadding*1.75});
  max-width: 100vh;

  &:before {
    border-top: 1px solid #aaa;
    content: '';
    position: absolute;
    width: 100%;
  }
  >div {
    background-color: white;
    padding: 0 10px;
    position: relative;
    line-height: 1;
  }
}
.AssetDownload-preview-width {
  bottom: 0;
  left: $assetDownloadPadding/4;
}

.AssetDownload-preview-height {
  transform: rotate(90deg);
  transform-origin: top left;
  left: 100%;
}

.AssetDownload-controls {
  align-items: start;
  display: grid;
  grid-gap: 1em;
  grid-template-areas:
    "meta"
    "format"
  ;
  grid-template-rows: auto 1fr;
}
.AssetDownload-controls-meta {
  grid-area: meta;
  margin: 0;
  dd {
    margin: .5em 0 0;
  }
  .Contrast,
  .AssetDownload-controls-group {
    margin-top: 1.5em;
  }
}
.AssetDownload-controls-format {
  grid-area: format;
  display: grid;
}
.AssetDownload-controls-colors {
  display: grid;
  grid-template-rows: auto 1fr;
  grid-column-gap: 10px;

  dt {
    grid-row: 1;
  }

  @include breakpoint($assetModalBreak) {
    grid-template-rows: auto auto auto 1fr;
    dt {
      grid-row: unset;
      margin-top: 10px;
    }
  }
}
.AssetDownload-controls-control--radio {
  display: grid;
  grid-gap: 12px;
  grid-template-columns: repeat(3,1fr);
}
.AssetDownload-controls-control--radio-radiobutton {
  align-items: center;
  border: 1px solid;
  cursor: pointer;
  display: flex;
  justify-content: center;
  padding: 5px;
    input {
    display: none;
  }
  &--checked {
    border-color: var(--color);
    background-color: var(--color);
    color: white;
  }
}
.AssetDownload-controls-sizes-size {
  align-items: center;
  display: grid;
  grid-template-columns: 20px auto 1fr;
  grid-template-rows: auto 1fr;

  input {
    appearance: none;
    background-color: transparent;
    border: unset;
    border-radius: 0;
    border-bottom: 1px solid;
    color: inherit;
    font-size: 100%;
    font: inherit;
    padding: 0;
    line-height: 1;
    &:focus {
      outline: 0;
    }
  }
  .error {
    grid-row: 2;
    grid-column: span 3;
  }
}

.AssetDownload-link {
  align-items: center;
  border: 1px solid;
  color: inherit;
  display: flex;
  justify-content: center;
  padding: 10px;
  text-decoration: none;
  transition: opacity .3s;
  stroke: var(--color);

  svg {
    margin-left: .5em;
  }
  svg,
  svg * {
    stroke: inherit;
  }

  &[disabled] {
    pointer-events: none;
    opacity: .5;
  }
}

.Dropdown-arrow {
  margin-top: 5px;
}

.ConfirmAssetTerms {
  align-items: center;
  align-self: flex-start;
  background-color: white;
  display: flex;
  flex-direction: column;
  margin: auto;
  max-width: 900px;
  padding: 3em;

  h1 {
    margin-top: 0;
  }
}

.ConfirmAssetTerms-buttons {
  display: grid;
  max-width: 600px;
  grid-gap: 1em;
  margin: 0 auto;
  padding-top: 2em;

  @include breakpoint($assetModalBreak) {
    justify-content: center;
    grid-template-rows: 1fr;
    grid-template-columns: auto auto;

    .ConfirmAssetTerms-buttons-item--default {
      order: 1;
    }
  }
}
.ConfirmAssetTerms-buttons-item {
  display: block;
  min-height: 50px;
  background-color: var(--color-strato);
  color: white;
  border: 0;
  font-size: 100%;
  padding: 1em 1.5em;
  transition: transform .3s;
  min-width: 200px;
  &:hover {
    transform: scale(1.05);
  }
}
.ConfirmAssetTerms-buttons-item--default {
  background-color: var(--color-heartland);
  color: var(--color-strato);
}

.Contrast-values {
  display: grid;
  grid-template-columns: auto auto 1fr;
  grid-gap: .75em;

  @media (min-width: 500px) {
    grid-gap: 1em;
  }
}

.Contrast-values-item {
  align-items: center;
  display: flex;
}
.Contrast-values-item svg {
  display: block;
  margin-right: .125em;
}

.Contrast-info {
  display: flex;
  // display: none;
  // @include breakpoint($tablet) {
  //   display: block;
  // }
}
