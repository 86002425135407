.ColorPicker {
  display: flex;
  flex-wrap: wrap;
  min-height: 140px;
}

.ColorPicker-color {
  button {
    background-color: transparent;
    border-radius: 50%;
    border-width: 1px;
    border-style: solid;
    border-color: transparent;
    display: block;
    width: 100%;
    height: 100%;
    padding: 5px;

    &:focus {
      outline: 0;
    }
  }
}

.ColorPicker-color-swatchwrap {
  height: 100%;
  position: relative;
}

.ColorPicker-color-inner {
  padding: 5px;
  width: 100%;
  height: 100%;
  position: relative;
}
.ColorPicker-color-swatch:last-child:not(:only-child) {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  clip-path: polygon(50% 0%, 100% 0%, 100% 100%, 50% 100%);
  z-index: 1;
}

.ColorPicker-color-swatch--transparent {
  background-size: cover;
  position: relative;

  // &:after {
  //   content: '';
  //   display: block;
  //   border: 1px solid red;
  //   position: absolute;
  //   width: 100%;
  //   top: 50%;
  //   transform: rotate(-45deg);
  // }
}

.ColorPicker-color--selected button {
  border-color: black;
}

// .ColorPicker-color--disabled {
//   pointer-events: none;
//   position: relative;
//   opacity: .5;

//   &:before,
//   &:after {
//     content: '';
//     display: block;
//     width: 60%;
//     height: 2px;
//     background-color: rgba(0,0,0,.7);
//     position: absolute;
//     top: 50%;
//     left: 50%;
//   }
//   &:before {
//     transform: translate(-50%,-50%) rotate(45deg);
//   }
//   &:after {
//     transform: translate(-50%,-50%) rotate(-45deg);
//   }
// }

.ColorPicker-color-swatch {
  width: 100%;
  height: 100%;
  border-radius: 50%;
}