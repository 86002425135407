@import "type.scss";
@import "stylevariables/spacing";

:root {
  --color: #1d253c;
  --color-strato: var(--color);
  --color-heartland: #f2ba42;
  --color-rustbelt: #d34e23;
  --color-claeys: #f2e4d6;
}

html {
  box-sizing: border-box;
}
* {
  box-sizing: inherit;
}

body {
  @extend .font-body;
  color: var(--color);
  font-size: 16px;
}

button {
  cursor: pointer;
  color: inherit;
}

img {
  display: block;
  width: 100%;
  height: auto;
}

a,
a:visited {
  color: var(--color-rustbelt);
  text-decoration: none;
}

.contain {
  max-width: 1100px;
  margin-left: auto;
  margin-right: auto;
  padding-left: 1em;
  padding-right: 1em;
}

.Tooltip {
  opacity: 1 !important;
  background-color: var(--color) !important;
}

.hover-mark {
  pointer-events: none;
}

.is-hidden {
  opacity: 0;
}

.Section-pad {
  padding-bottom: 50px;
  padding-top: $navHeight;

  @include breakpoint($tablet) {
    padding-bottom: 100px;
  }
}

.Section-pad--top {
  padding-top: $navHeight;
}
