@import "~stylevariables/breakpoints";
@import "~stylevariables/type";

$sectionHeaderMargin: (
  'base': 2rem,
  'tablet': 4rem
);


.Section {
  transition: opacity 0.3s;
  // opacity: 0;
  // &.in { opacity: 1; }

  video {
    max-width: 100%;
    @include breaks('margin-top', $sectionHeaderMargin);
  }

  @include breakpoint($tablet) {}
}

.Section + .Section  {
  margin-top: 0;
}

$sectionInnerMargin: (
  'base': 2rem,
  'tablet': 4rem
);
.Section-inner {
  @include breaks('margin-top', $sectionInnerMargin);
}

.section-contain {
  max-width: 800px;
  margin-left: auto;
  margin-right: auto;
}
.Section-header {
  @extend .section-contain;
  @include breaks('margin-top', $sectionHeaderMargin);
}

.Section-heading {
  text-align: center;
}

.Section-explainer {
  margin-top: 1em;

  h2 {
    font-family: $family-aktiv;
    font-weight: 700;
    line-height: 1.7;
    @include fontSizes($subheadingSizes);
  }
}

.Section .ColorCombo {
  margin: 4em 0;
}

.Download-links .AssetDownload-link {
  color: #000;
  margin: 0.5rem;
}
