@import "~stylevariables/spacing";
@import "~stylevariables/breakpoints";

.SandboxSample {
  width: 100%;
  height: 100%;
  position: relative;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.SandboxSample--border {
  .SandboxSample-choices-choice,
  .SandboxSample-samples {
    background-color: rgba(0,0,0,.01);
    //border: 1px solid #cecece;
  }

}
.SandboxSample-samples {
  @include breaks('padding', $boxpad);
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: center;
  flex-direction: column;
  max-height: 100%;
  overflow-y: auto;
  position: relative;
}
.SandboxSample-samples-item + .SandboxSample-samples-item {
  margin-top: 1rem;
}
.SandboxSample-samples svg {
  display: block;
  max-height: 40vh;
  margin: 0 auto;

  * {
    fill: inherit;
  }
}

.SandboxSample-choices {
  display: flex;
  background-color: #fff;
  width: 100%;
}

.SandboxSample-choices-choice {
  margin-bottom: 3px;
  margin-left: 3px;
  cursor: pointer;
  width: 100%;

  &:first-child {
    margin-left: 0;
  }

  svg {
    display: block;
    width: 100%;
    height: auto;
    max-height: 100px;
  }
}

.SandboxSample-actions {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  z-index: 1;
  padding: 5px 10px;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;

  a {
    background-color: rgba(255,255,255,.25) !important;
    color: inherit;
    text-decoration: none;
    display: inline-flex;
    justify-content: center;
    padding: 3px 10px;

    > div {
      align-items: center;
      display: flex;
      justify-content: center;

      svg {
        margin-left: .5em;
        * {
          stroke: inherit;
        }
      }
    }
  }
  .LogoColors-download {
    position: absolute;
    width: 100%;
    height: 100%;
    cursor: pointer;
  }
}

.SandboxSample-actions-download-mark {
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  padding: .25em .5em;
}

.LogoColors-download >div {
  align-items: center;
  display: flex;
}