@import "~stylevariables/colors";
@import "~stylevariables/breakpoints";

.Logos {
  display: grid;
  grid-gap: 6rem;
}

.Logos-section-heading {
  margin-bottom: 1em;
}
.Logos-section-grid {
  // display: grid;
  // //grid-auto-rows: 35vmin;
  // grid-gap: 2em;

  // @include breakpoint(600px) {
  //   grid-template-columns: repeat(2, 1fr);
  // }
}

.Logo {
  display: flex;
  flex-direction: column;
}
.Logo-title {
  text-align: center;
}

.Logo,
.LogoColors-download {
  position: relative;
  cursor: pointer;

  svg {
    display: block;
  }
  .hover-mark {
    align-items: center;
    display: none;
    position: absolute;
    bottom: 0;
    padding: 3px 6px;
    z-index: 1;
  }
  &:hover .hover-mark {
    display: flex;
  }
}

.Logo .hover-mark {
  left: 50%;
  transform: translateX(-50%);
}
.Assets-item .hover-mark {
  right: 0;
  max-width: 30px;
}

.Logo a {
  text-decoration: none;
  color: inherit;
}

// .Logo--full {
//   grid-column: span 6;

//   .Logo-image svg {
//     width: 50%;
//     height: 50%;
//   }
// }

// .Logo--half {
//   grid-column: span 3;

//   .Logo-image svg {
//     width: 75%;
//     height: 75%;
//   }
// }

// .Logo--third {
//   grid-column: span 2;

//   .Logo-image {
//     padding: 2em;
//   }

//   .Logo-image svg {
//     width: 100%;
//     height: 100%;
//   }
// }

.Logo-image {
  align-items: center;
  display: flex;
  justify-content: center;
  height: 100%;
  background-color: $lightgray;

  svg {
    display: block;
    padding: 2em;
    width: 100%;
  }
}

.no-pad .Logo-image svg {
  padding: 0;
}

.Logo-image.no-pad svg {
  padding: 0;
}

.Logo-title {
  margin-top: .5em;
}

.LogosColors {
  margin-top: 6em;
}
.LogosColors-row {
  display: grid;
  grid-gap: .25em;

  &+.LogosColors-row {
    margin-top: .25em;
  }
  @include breakpoint($tablet) {
    grid-gap: 1em;

    &+.LogosColors-row {
      margin-top: 1em;
    }
  }
  svg {
    display: block;
  }
}
