@import "~stylevariables/breakpoints";

.Nav {
  display: grid;
  a {
    color: inherit;

    &:focus {
      outline: 0;
    }
  }
}

.Nav-item {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-bottom: 50%;

  div {
    text-align: center;
    align-items: center;
    display: flex;
    justify-content: center;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
    height: 100%;
  }
  svg {
    display: block;
    width: 100%;
    height: 100%;
  }

}

.Nav-item__image {
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
