@import '~stylevariables/breakpoints';
@import "~stylevariables/colors";

$assetModalBreak: 800px;

.arrow-link {
  align-items: center;
  display: flex;
  justify-content: flex-start;
  position: relative;

  svg {
    display: block;
    height: auto;
    margin-right: 10px;
    transition: all 0.3s;
    width: 15px;
  }

  @include breakpoint($tablet) {
     svg { width: 20px }
  }

  @include breakpoint($desktop) {
    display: inline-block;

    svg {
      display: block;
      height: auto;
      left: -30px;
      position: absolute;
      margin-right: 0;
      top: 5px;
      transition: all 0.3s;
    }

    &:hover {
      svg { left: -35px; }
    }
  }
}

.PhotoAlbum-Photos {
  list-style: none;
  margin: 0;
  padding: 0;
}

.PhotoAlbum-Photo {
  opacity: 1;
  transition: opacity 0.3s;

  &.loading { opacity: 0; }
}

.PhotoAlbum-Photo .AssetDownload-link {
  border: none;
  padding: 0;

  .hover-mark {
    align-items: center;
    background-color: $strato;
    bottom: 0;
    display: none;
    height: 20px;
    width: 25px;
    position: absolute;
    right: 0;
    z-index: 1;
  }

  svg {
    bottom: 3px;
    display: none;
    position: absolute;
    right: 5px;
    z-index: 1;

    * { stroke: $claeys; }
  }

  &:hover {
    cursor: pointer;

    .hover-mark { display: flex; }
    svg { display: block; }
  }
}
