@import '~stylevariables/breakpoints';
@import "~stylevariables/colors";
@import '~stylevariables/type';
@import "~stylevariables/spacing";


.Grid {
  display: grid;
  grid-template-rows: auto 1fr;
  list-style: none;
  margin: 0;
  padding: 0;

  @include breakpoint(500px) {
    &.Grid-module--3,
    &.Grid-module--4 {
      grid-template-columns: 1fr 1fr;
    }
  }

  @include breakpoint(800px) {
    &.Grid-module--3 {
      grid-column-gap: 1rem;
      grid-row-gap: 1rem;
      grid-template-columns: repeat(3,1fr);
    }

    &.Grid-module--4 {
      grid-template-columns: repeat(4,1fr);
    }
  }

  @include breakpoint(1440px) {}
}

.Assets,
.Authors,
.Logos-section-grid {
  display: grid;
  grid-gap: 1.5rem;

  @include breakpoint(600px) {
    grid-template-columns: 1fr 1fr;
  }

  @include breakpoint(800px) {
    grid-template-columns: repeat(3, 1fr);
  }
}

.Assets-item {
  text-align: center;

  svg {
    height: auto;
    display: block;
    width: 100%;
  }
}

.Asset-types {
  display: grid;
  grid-gap: 5em;
}
.Asset-type {

}

.Asset-type-subtitle {
  margin-top: 0;
  max-width: 600px;
  padding-bottom: 1rem;
}

.Assets {
  margin-top: 2em;
}

.Authors-item {
  display: grid;
  grid-template-areas:
    "photo name"
    "photo location"
  ;
  grid-template-columns: auto 1fr;
  grid-column-gap: 1em;
}

.Authors-item-name {
  grid-area: name;
}

.Authors-item-photo {
  grid-area: photo;
  border-radius: 50%;
  width: 50px;
  height: 50px;
}

.Authors-item-location {
  grid-area: location;
}
#root .at input[type="tel"], #root .at input[type="submit"], #root .at input[type="text"], .at input[type="password"], .at input[type="date"], .at input[type="datetime"], .at input[type="datetime-local"], .at input[type="month"], .at input[type="week"], .at input[type="email"], .at input[type="number"], .at input[type="search"], .at input[type="tel"], .at input[type="time"], .at input[type="url"], .at input[type="color"], .at textarea{
  border-radius: 0;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  color: $strato;
}
#root .at{
  .at-title, .at-legend{
    display: none
  }

 form{
    font-family: $family-aktiv;
    .at-form-submit{
      text-align: center;
    }
    .at-text{
      color: $strato;
    }
    input[type="submit"]{
      float: none;
      background-color: $heartland;
      color: $skagen;
      font-family: $family-aktiv;
      font-weight: 700;
      text-transform: uppercase;
      transition: 0.3s;

      &:hover{
        color: $heartland;
        background-color: $skagen;
      }
    }
  }
}

.PhotoSection {
  margin: 1rem 0;

  &.halfWidth {
    align-items: center;
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    img:nth-of-type(even) {
      margin-left: 0.5rem;
    }
    img:nth-of-type(odd) {
      margin-right: 0.5rem;
    }
  }
}
