@import "~stylevariables/breakpoints";
@import "~stylevariables/spacing";
@import "~stylevariables/colors";

.NavBar {
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 2;
  svg * {
    fill: inherit;
  }
}

.App {
  // padding-top: $navHeight;
}

.NavBar-bar {
  align-items: center;
  display: grid;
  height: $navHeight;
  padding: 8px 16px;
  position: relative;
  z-index: 1;

  grid-template-columns: 1fr 1fr 1fr;
  grid-template-areas: ". home right";

  &.transparent {
    background-color: rgba(255, 255, 255, 0) !important;
  }

  @include breakpoint(800px) {
    grid-template-columns: 265px 1fr 265px;
  }
}

.NavBar-right {
  grid-area: right;
  display: flex;
  justify-content: flex-end;

  .NavBar-toggle {
    margin-left: 2em;
  }
  svg {
    display: block;
    width: 30px;
    height: auto;
  }
}

a.NavBar-mainlink {
  align-items: center;
  display: none;
  color: inherit;
  // text-transform: unset;
  line-height: 1;

  svg {
    width: 20px;
    height: 100%;
    fill: none;
    stroke: inherit;
    margin-right: 10px;

    * {
      stroke: inherit;
    }
  }

  @include breakpoint(800px) {
    display: flex;
  }
}

.NavBar-menu .TextNav {
  min-height: calc(100vh - #{$navHeight});
}
.TextNav {
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  padding: 0 0 100px;
  position: relative;

  @include breakpoint($tablet) {
    padding: 50px 0;
  }
}

a.TextNav-link {
  color: inherit;
  display: block;
  font-size: 18px;
  padding: .5em 0;
  text-decoration: none;

  @include breakpoint($tablet) {
    font-size: 24px;
  }
}

// .TextNav-label {
//   padding-top: 100px;
// }

.NavBar-toggle {
  //position: fixed;
  //top: 12px;
  //right: 12px;
  background-color: transparent;
  border: 0;
  &:focus {
    //outline: 0;
  }
}
.NavBar-bar-homelink {
  grid-area: home;
  height: 30px;
}
.NavBar-bar-homelink--large {
  height: 40px;
}
.NavBar-bar-homelink svg {
  display: block;
  height: 100%;
  margin: 0 auto;
}

.NavBar-borderlink {
  border: 2px solid;
  font-size: 0.8rem;
  padding: 5px;
  text-transform: uppercase;
}
