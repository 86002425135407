@import "~stylevariables/breakpoints";
@import "~stylevariables/spacing";

.Colors {
  align-items: flex-start;
}

.Color--primary .Color-swatch {
  padding-bottom: 60%;
}

.Color-swatch {
  padding-bottom: 30%;
  height: 0;

  .hover-mark {
    visibility: hidden;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
    text-align: center;
  }

  &:hover .hover-mark {
    visibility: visible;
  }
}

.Color {
  position: relative;
  width: 100%;
  stroke: var(--color);

  .Color-swatch {
    position: relative;
  }
}
.Color-inner {
  display: grid;
  //grid-template-columns: 1fr minmax(120px,1fr);
  grid-gap: 1em;

  @include breakpoint(420px) {
    grid-template-columns: 1fr;
  }
}

.ColorStoryCompare > div > div:nth-child(3) > div:nth-child(2) {
  background-color: var(--color-heartland)
}

.ColorStories {
  display: grid;
  grid-gap: 3em;
  list-style-type: none;
  padding: 0;
  margin-top: 3em;
}
.ColorStories-item {
  display: grid;
  grid-template-areas:
    "image"
    "heading"
    "text"
    "credit"
  ;
  grid-column-gap: 3em;
  grid-row-gap: 1em;

  @include breakpoint(500px) {
    grid-template-columns: 200px 1fr;
    grid-template-rows: auto 1fr;

    grid-template-areas:
      "image heading"
      "image text"
      "image credit"
    ;
  }
  @include breakpoint(600px) {
    grid-template-columns: 300px 1fr;
    grid-template-rows: auto 1fr;
    grid-column-gap: 3em;
    grid-row-gap: 1em;
    grid-template-areas:
      "image heading"
      "image text"
      "image credit"
    ;
  }
  @include breakpoint($tablet) {
    grid-template-columns: 350px 1fr;
    grid-template-rows: auto 1fr;
    grid-column-gap: 3em;
    grid-row-gap: 1em;
    grid-template-areas:
      "image heading"
      "image text"
      "image credit"
    ;
  }
}

.ColorStories-item-heading {
  grid-area: heading;
}
.ColorStories-item-text {
  grid-area: text;
}
.ColorStories-item-image {
  grid-area: image;
}
.ColorStories-item-credit {
  grid-area: credit;
}

.Colors {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 1em;
}

@include breakpoint(420px) {
  .Colors {
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 1em;
  }
  .Color-swatch-meta {
    padding: 4px;
  }
}

@include breakpoint(600px) {
  .Colors {
    grid-template-columns: repeat(4, 1fr);
    grid-row-gap: 50px;
  }
  .Color--primary {
    grid-column: span 2;
  }
  .Color--secondary {
    grid-column: span 1;
  }
}

@include breakpoint(900px) {
  .Colors {
    grid-template-columns: repeat(20, 1fr);
    grid-row-gap: 50px;
  }
  .Color--primary {
    grid-column: span 5;
  }
  .Color--secondary {
    grid-column: span 4;
  }
  .Color--primary .Color-swatch {
    padding-bottom: 100%;
  }
  .Color-swatch {
    padding-bottom: 30%;
  }
}

$colormargin: (
  'base': 0,
  'tablet': 1.5rem
);
$valuemargin: (
  'base': .25rem,
  'tablet': .75rem
);
.Color-label {
  @include breaks('margin-top', $colormargin);
}
.Color-values {
  @include breaks('margin-top', $colormargin);
}
.Color-value {
  @include breaks('margin-top', $valuemargin);
}

.Color-value-label {
  padding-right: .5em;
}
