$lightgray: #F8F8F8;

$strato: #1d253c;
$claeys: #f2e4d6;
$skagen: #004d80;
$heartland: #f2ba42;
$clearSkies: #eef4f8;
$calm: #85b6c5;
$rustBelt: #d34e23;
$buddy: #b88a57;
$truman: #653727;
