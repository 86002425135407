$tablet: 700px;
$desktop: 1200px;

$breakpoints: (
  'base': 0px,
  'tablet': $tablet,
  'desktop': $desktop
);

@mixin breakpoint($breakpoint) {
  @media (min-width: $breakpoint) {
    @content;
  }
}

@mixin breaks($property,$sizes) {
  @each $size in $sizes {
    $break: nth($size,1);
    @include breakpoint(map-get($breakpoints,$break)) {
      #{$property}: #{inspect(map-get($sizes, $break))};
    }
  }
}

@mixin fontSizes($sizes) {
  @include breaks('font-size',$sizes);
}